import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import InfiniteScrollingTable, { Column } from '../common/InfiniteScrollingTable';
import { Organization } from '../common/types';

interface Order {
  outboundOrderId: string;
  merchantId: string;
  lineItems?: Array<{ quantity: number }>;
  status: OutBoundOrderStatus; // Add other possible statuses if needed
  marketplaceData: Record<string, any>;
}

interface Merchant {
  merchantId: string;
  name: string;
}

interface OrdersTableProps {
  orders: Order[];
  merchants: Merchant[];
  organization: Organization;
  fetchMoreData: () => void;
  hasMore: boolean;
  isFetching: boolean;
}

export enum OutBoundOrderStatus {
  PENDING = 'pending',
  FAILED = 'failed',
  CREATED = 'created',
  COMPLETED = 'completed',
  ON_HOLD = 'on-hold',
  PAUSED = 'paused',
  CANCELLED = 'cancelled',
}

const getStatusColor = (status: OutBoundOrderStatus) => {
  switch (status) {
    case OutBoundOrderStatus.COMPLETED:
      return 'text-green-700 bg-green-100';
    case OutBoundOrderStatus.PENDING:
      return 'text-yellow-700 bg-yellow-100';
    case OutBoundOrderStatus.FAILED:
      return 'text-red-700 bg-red-100';
    case OutBoundOrderStatus.CREATED:
      return 'text-gray-700 bg-gray-100';
    case OutBoundOrderStatus.ON_HOLD:
      return 'text-gray-700 bg-orange-100';
    case OutBoundOrderStatus.PAUSED:
      return 'text-gray-700 bg-purple-100';
    case OutBoundOrderStatus.CANCELLED:
      return 'text-gray-700 bg-red-100';
    default:
      return '';
  }
};

const OrdersTable: React.FC<OrdersTableProps> = ({
  orders,
  merchants,
  organization,
  fetchMoreData,
  hasMore,
  isFetching,
}) => {
  const navigate = useNavigate();
  const columns: Column<Order>[] = [
    {
      key: 'orderId',
      title: 'Order ID',
      accessor: (order) => (
        <Link
          className='hover:underline'
          to={`/${organization.organizationId}/outbound/${order?.outboundOrderId}`}
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          {order?.marketplaceData?.orderDisplayId || order.outboundOrderId}
        </Link>
      ),
      headerClassnamePerColumn: 'py-3.5 pl-4 pr-3 text-left text-gray-900 sm:pl-6',
    },
    {
      key: 'clientName',
      title: 'Client Name',
      accessor: (order) => (
        <span className='capitalize'>
          {merchants.find((m) => m.merchantId === order.merchantId)?.name || ''}
        </span>
      ),    },
    {
      key: 'numberOfProducts',
      title: 'No. of Products',
      accessor: (order) => <div className='pl-10'>{order.lineItems?.length || 0}</div>,
    },
    {
      key: 'quantity',
      title: 'Quantity',
      accessor: (order) => (
        <div className='pl-10'>
          {order.lineItems?.reduce((acc, product) => acc + product.quantity, 0) || 0}
        </div>
      ),
    },
    {
      key: 'status',
      title: 'Status',
      accessor: (order) => (
        <div className='relative group w-fit mx-auto'>
          <div
            className={`py-1 px-3 text-sm mt-2 rounded-full capitalize ${getStatusColor(
              order.status,
            )}`}
          >
            {order.status}
          </div>
        </div>
      ),
      headerClassnamePerColumn: 'inline-flex justify-center w-full',
    },
  ];
  const handleOrderClick = (order) => {
    navigate(`${order.outboundOrderId}`);
  };

  return (
    <InfiniteScrollingTable
      onSort={() => {}}
      totalDataCount={orders.length}
      columns={columns}
      data={orders}
      fetchMoreData={fetchMoreData}
      hasMore={hasMore}
      loadingData={isFetching && orders.length === 0}
      onRowClick={handleOrderClick}
    />
  );
};

export default OrdersTable;
